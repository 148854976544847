import classNames from 'classnames'
import React, { ReactNode } from 'react'

type Props = {
  children: ReactNode
  className?: string
}

const TitleSection: React.FC<Props> = ({ children, className }: Props) => (
  <section className={classNames('bg-white py-5', className)}>
    <div className="mx-auto flex items-center justify-between">{children}</div>
  </section>
)

export default TitleSection
