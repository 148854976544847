import React from 'react'

import { appActions } from '@features/app/reducers'
import KycFormSentModal from '@features/kyc/components/KycFormSentModal'

import { useAppDispatch, useAppSelector } from '@hooks/redux'

import MODAL_NAME from '@constants/modal-name'

const JobListingKycFormSentModal = () => {
  const dispatch = useAppDispatch()
  const modalName = useAppSelector((state) => state.appReducer.modalName)

  return (
    <KycFormSentModal
      open={modalName === MODAL_NAME.KYC_FORM_SENT_MODAL}
      onClose={() => dispatch(appActions.removeModal())}
    />
  )
}

export default JobListingKycFormSentModal
