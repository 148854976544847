import classNames from 'classnames'
import React, { ReactNode } from 'react'

type Props = {
  children: ReactNode
  className?: string
}

const Caption: React.FC<Props> = ({ children, className }: Props) => (
  <span
    className={classNames('text-primary-gray', 'whitespace-normal', className)}
  >
    {children}
  </span>
)

export default Caption
