import React from 'react'

import Button from '@components/button'

import Modal from '../index'

type ModalProps = {
  open: boolean
  onClose: () => void
  onSubmit: () => void
}

const RepostModal = ({ open, onClose, onSubmit }: ModalProps) => (
  <Modal onClose={onClose} open={open} isCloseButtonHidden>
    <div className="max-w-sm">
      <p className="text-center">
        Apakah Anda yakin untuk mendaftarkan kembali pekerjaan ini?
      </p>
      <div className="mt-2.5 flex">
        <div className="mr-2 flex-1">
          <Button
            type="button"
            appearance="outline"
            onClick={onClose}
            className="w-full"
          >
            Batal
          </Button>
        </div>
        <div className="flex-1">
          <Button
            type="button"
            appearance="primary"
            onClick={onSubmit}
            className="w-full"
          >
            Ya
          </Button>
        </div>
      </div>
    </div>
  </Modal>
)

export default RepostModal
