import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment } from 'react'

import Button from '@components/button'

type ModalProps = {
  open: boolean
  header?: React.ReactNode
  title?: string | React.ReactNode
  buttons?: React.ReactNode
  buttonTextRight?: string
  buttonTextLeft?: string
  onClose: () => void
  onSubmit: () => void
}

const QuestionModal = ({
  open,
  header,
  buttonTextLeft,
  buttonTextRight,
  title = 'Apakah Anda yakin untuk menon-aktifkan pekerjaan ini?',
  buttons,
  onClose,
  onSubmit,
}: ModalProps) => (
  <Transition appear show={open} as={Fragment}>
    <Dialog
      as="div"
      className="fixed inset-0 z-50 overflow-y-auto bg-gray-900/50"
      onClose={onClose}
    >
      <div className="min-h-screen px-4 text-center">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0" />
        </Transition.Child>

        {/* This element is to trick the browser into centering the modal contents. */}
        <span className="inline-block h-screen align-middle" aria-hidden="true">
          &#8203;
        </span>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="my-8 inline-block w-full max-w-md transform overflow-hidden rounded-2xl border-2 bg-white p-6 text-left align-middle shadow-xl transition-all">
            <div>
              {header}
              {typeof title === 'string' ? (
                <p className="text-center">{title}</p>
              ) : (
                title
              )}
              {buttons || (
                <div className="mt-2.5 flex">
                  <div className="mr-2 flex-1">
                    <Button
                      type="button"
                      appearance="outline"
                      onClick={onClose}
                      className="w-full"
                    >
                      {buttonTextLeft || 'Batal'}
                    </Button>
                  </div>
                  <div className="flex-1">
                    <Button
                      type="button"
                      appearance="primary"
                      onClick={onSubmit}
                      className="w-full"
                    >
                      {buttonTextRight || 'Ya'}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Transition.Child>
      </div>
    </Dialog>
  </Transition>
)

export default QuestionModal
