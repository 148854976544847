import React from 'react'

import Button from '@components/button'
import Modal from '@components/modal'

type Props = {
  isOpen: boolean
  onClose: () => void
}

const CandidateSearchNoticeModal = ({ isOpen, onClose }: Props) => (
  <Modal
    open={isOpen}
    onClose={onClose}
    isCloseButtonHidden
    isHeaderHidden
    className="w-[30rem] p-6"
  >
    <div className="">
      <p className="text-center">
        Fitur Cari Kandididat hanya untuk Loker{' '}
        <span className="font-semibold">aktif</span>, periksa kembali status
        loker Anda.
      </p>
      <div className="mt-4">
        <Button
          onClick={onClose}
          appearance="primary"
          type="button"
          className="w-full"
        >
          Mengerti
        </Button>
      </div>
    </div>
  </Modal>
)

export default CandidateSearchNoticeModal
