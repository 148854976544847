import { useRouter } from 'next/router'
import React from 'react'
import { useDispatch } from 'react-redux'
import useSWRImmutable from 'swr/immutable'

import { appActions } from '@features/app/reducers'
import { jobListingQueriesToServerRequest } from '@features/jobs/helpers'
import useJobListingQuery from '@features/jobs/hooks/useJobListingQuery'
import {
  userKycInReview,
  userKycPending,
  userKycRejected,
} from '@features/kyc/helpers'
import { GetEmployerProfileAPIResponseData } from '@features/kyc/types'

import Button from '@components/button'
import H1 from '@components/typography/heading-one/H1'
import TitleSection from '@components/ui/title-section'

import { useAppSelector } from '@hooks/redux'

import { CLICK_ON_POST_NEW_JOB } from '@constants/event-tracking'
import MODAL_NAME from '@constants/modal-name'
import { PATHS } from '@constants/paths'
import { EMPLOYERS_PROFILE_URL } from '@constants/url'

import trackEvents from '@utils/track-events'

const JobListingTitle = () => {
  const jobListingQueries = useAppSelector(
    (state) => state.jobReducer.jobListingQueries
  )
  const router = useRouter()
  const { data: employerProfileData } =
    useSWRImmutable<GetEmployerProfileAPIResponseData>(EMPLOYERS_PROFILE_URL)
  const dispatch = useDispatch()
  const companyId = employerProfileData?.company?.id || ''
  const { data, error } = useJobListingQuery({
    companyId,
    query: jobListingQueriesToServerRequest(jobListingQueries),
  })

  const records = (data || []).flatMap((v) => v.records)

  const handleCreateJobPostClicked = () => {
    trackEvents(CLICK_ON_POST_NEW_JOB)
    if (!employerProfileData || !employerProfileData.company?.kycStatus) return
    if (
      (userKycPending({
        companyKyc: employerProfileData.company.kycStatus,
        employerKyc: employerProfileData.kycStatus,
      }) ||
        userKycRejected({
          companyKyc: employerProfileData.company.kycStatus,
          employerKyc: employerProfileData.kycStatus,
        })) &&
      records.length > 0
    ) {
      dispatch(appActions.setModalName(MODAL_NAME.KYC_NOTICE_MODAL))
      return
    }
    if (
      userKycInReview({
        companyKyc: employerProfileData.company.kycStatus,
        employerKyc: employerProfileData.kycStatus,
      }) &&
      records.length > 0
    ) {
      dispatch(appActions.setModalName(MODAL_NAME.KYC_INREVIEW_NOTIFY_MODAL))
      return
    }
    router.push(PATHS.CREATE_JOB)
  }

  return (
    <TitleSection className="!py-0">
      <H1>Daftar loker</H1>
      {data && !error && (
        <div className="hidden lg:block">
          <Button
            type="button"
            appearance="primary"
            onClick={handleCreateJobPostClicked}
            className="coachmark-jobs_home-step_1"
          >
            Buat loker baru
          </Button>
        </div>
      )}
    </TitleSection>
  )
}

export default JobListingTitle
