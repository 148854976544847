/* eslint-disable import/prefer-default-export */
export const GENDERS = {
  MALE: 'Male',
  FEMALE: 'Female',
  BOTH: 'Both',
} as const

export const GENDER_OPTIONS = [
  {
    label: 'Tidak ada preferensi',
    value: GENDERS.BOTH,
  },
  {
    label: 'Laki-laki',
    value: GENDERS.MALE,
  },
  {
    label: 'Perempuan',
    value: GENDERS.FEMALE,
  },
]

export type GENDER_OPTIONS_TYPE = (typeof GENDER_OPTIONS)[number]
