/* eslint-disable */
import React from 'react'

const WhiteCloseIcon = () => (
  <svg
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1102_29028)">
      <path
        d="M14.1875 29.8125L29.8125 14.1875"
        stroke="white"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M29.8125 29.8125L14.1875 14.1875"
        stroke="white"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1102_29028">
        <rect
          width="16.6667"
          height="16.6667"
          fill="white"
          transform="translate(13.6665 13.667)"
        />
      </clipPath>
    </defs>
  </svg>
)

export default WhiteCloseIcon
