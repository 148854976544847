import differenceInCalendarYears from 'date-fns/differenceInCalendarYears'
import differenceInDays from 'date-fns/differenceInDays'
import differenceInHours from 'date-fns/differenceInHours'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import format from 'date-fns/format'
import id from 'date-fns/locale/id'
import parse from 'date-fns/parse'
import parseISO from 'date-fns/parseISO'

export const expiredDateDiff = (jobExpiredDate: Date) =>
  differenceInDays(jobExpiredDate, new Date())

export const calculateAgeInYears = (dateOfBirth: string): number => {
  if (dateOfBirth) {
    return differenceInCalendarYears(
      new Date(),
      parse(dateOfBirth, 'yyyy-MM-dd', new Date())
    )
  }

  return 0
}

export const formatDateInID = (
  dateInString: string,
  dateFormat = 'dd MMMM yyyy'
): string => {
  if (dateInString) {
    return format(parseISO(dateInString), dateFormat, {
      locale: id,
    })
  }

  return ''
}

export const formatDateIntervalInLocale = (
  startDate: string,
  endDate: string,
  isCurrent?: boolean,
  startDateFormat = 'yyyy-MM',
  endDateFormat = 'yyyy-MM'
) => {
  const parsedStartDate = startDate
    ? parse(startDate, startDateFormat, new Date())
    : ''
  const parsedEndDate = endDate ? parse(endDate, endDateFormat, new Date()) : ''

  const formattedStartDate = parsedStartDate
    ? format(parsedStartDate, 'MMMM yyyy', {
        locale: id,
      })
    : ''
  const formattedEndDate =
    parsedEndDate !== ''
      ? format(parsedEndDate, 'MMMM yyyy', {
          locale: id,
        })
      : ''

  if (isCurrent) {
    return `${formattedStartDate} - Sekarang`
  }

  return `${formattedStartDate} ${
    formattedEndDate ? `- ${formattedEndDate}` : ''
  }`
}

export const expiredHourDiff = (jobExpiredDate: Date) =>
  differenceInHours(jobExpiredDate, new Date())

export const expiredMinuteDiff = (jobExpiredDate: Date) =>
  differenceInMinutes(jobExpiredDate, new Date())
