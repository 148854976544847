import React from 'react'

import { appActions } from '@features/app/reducers'

import KycNoticeModal from '@components/modal/kyc-notice-modal'

import { useAppDispatch, useAppSelector } from '@hooks/redux'

import MODAL_NAME from '@constants/modal-name'

const JobListingKycNoticeModal = () => {
  const modalName = useAppSelector((state) => state.appReducer.modalName)
  const dispatch = useAppDispatch()

  return (
    <KycNoticeModal
      open={modalName === MODAL_NAME.KYC_NOTICE_MODAL}
      onClose={() => dispatch(appActions.removeModal())}
    />
  )
}

export default JobListingKycNoticeModal
