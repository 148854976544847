import React from 'react'

import { appActions } from '@features/app/reducers'

import BoostNoticeModal from '@components/modal/boost-notice-modal'

import { useAppDispatch, useAppSelector } from '@hooks/redux'

import MODAL_NAME from '@constants/modal-name'

const JobListingBoostNoticeModal = () => {
  const modalName = useAppSelector((state) => state.appReducer.modalName)
  const dispatch = useAppDispatch()
  return (
    <BoostNoticeModal
      isOpen={modalName === MODAL_NAME.BOOST_NOTICE_MODAL}
      onClose={() => dispatch(appActions.removeModal())}
    />
  )
}

export default JobListingBoostNoticeModal
