export const JOB_STATUS = {
  ACTIVE: 'Active',
  PENDING: 'Pending',
  INACTIVE: 'InActive',
  EXPIRED: 'Expired',
  REJECTED: 'Rejected',
} as const

export enum JOB_STATUS_ENUM {
  ACTIVE = 'Active',
  INACTIVE = 'InActive',
  PENDING = 'Pending',
  EXPIRED = 'Expired',
}
type JobStatusOption = {
  name: string
  value: JOB_STATUS_ENUM[]
}
export const JOBSTATUS_OPTIONS: JobStatusOption[] = [
  {
    name: 'Aktif',
    value: [JOB_STATUS_ENUM.ACTIVE],
  },
  {
    name: 'Non-aktif',
    value: [JOB_STATUS_ENUM.INACTIVE, JOB_STATUS_ENUM.PENDING],
  },
  {
    name: 'Kadaluarsa',
    value: [JOB_STATUS_ENUM.EXPIRED],
  },
]

export type JobStatus = (typeof JOB_STATUS)[keyof typeof JOB_STATUS]
