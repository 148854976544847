/* eslint-disable import/prefer-default-export */
export const EMPLOYMENT_TYPES = {
  CONTRACT: 'Contract',
  FULL_TIME: 'FullTime',
  PART_TIME: 'PartTime',
  PROJECT: 'Project',
  INTERNSHIP: 'Internship',
} as const

export const EMPLOYMENT_TYPE_OPTIONS = [
  {
    label: 'Full Time',
    value: EMPLOYMENT_TYPES.FULL_TIME,
  },
  {
    label: 'Part Time',
    value: EMPLOYMENT_TYPES.PART_TIME,
  },
  {
    label: 'Kontrak',
    value: EMPLOYMENT_TYPES.CONTRACT,
  },
  {
    label: 'Proyek',
    value: EMPLOYMENT_TYPES.PROJECT,
  },
  {
    label: 'Magang',
    value: EMPLOYMENT_TYPES.INTERNSHIP,
  },
] as const

export const FINITE_EMPLOYMENT_TYPES = [
  EMPLOYMENT_TYPES.INTERNSHIP,
  EMPLOYMENT_TYPES.CONTRACT,
  EMPLOYMENT_TYPES.PROJECT,
] as const

export type EmploymentType =
  (typeof EMPLOYMENT_TYPES)[keyof typeof EMPLOYMENT_TYPES]

export const LocalizedEmploymentType = {
  [EMPLOYMENT_TYPES.FULL_TIME]: 'Karyawan Penuh Waktu',
  [EMPLOYMENT_TYPES.CONTRACT]: 'Karyawan Kontrak',
  [EMPLOYMENT_TYPES.INTERNSHIP]: 'Karyawan Magang',
  [EMPLOYMENT_TYPES.PART_TIME]: 'Karyawan Part Time',
  [EMPLOYMENT_TYPES.PROJECT]: 'Proyekan / Borongan',
}
