import { get } from '@helpers/fetch-wrapper'
import Image from 'next/image'
import { useRouter } from 'next/router'
import React from 'react'
import useSWRImmutable from 'swr/immutable'

import { appActions } from '@features/app/reducers'
import { GetJobListingAPIResponse } from '@features/jobs/hooks/useJobListingQuery'
import {
  userKycInReview,
  userKycPending,
  userKycRejected,
} from '@features/kyc/helpers'
import { GetEmployerProfileAPIResponseData } from '@features/kyc/types'

import Button from '@components/button'
import Caption from '@components/typography/caption'
import H1 from '@components/typography/heading-one'

import { useAppDispatch } from '@hooks/redux'

import { CLICK_ON_POST_NEW_JOB } from '@constants/event-tracking'
import MODAL_NAME from '@constants/modal-name'
import { PATHS } from '@constants/paths'
import { EMPLOYERS_PROFILE_URL, JOB_LISTING_URL } from '@constants/url'

import trackEvents from '@utils/track-events'

/* import { JOB_SEARCH_CLICKED_ON_POST_NEW_JOB } from '@constants/event-tracking'

import trackEvents from '@utils/track-events' */

const CreateJobHero: React.FC = () => {
  const router = useRouter()
  const dispatch = useAppDispatch()
  const { data: employerProfileData } =
    useSWRImmutable<GetEmployerProfileAPIResponseData>(EMPLOYERS_PROFILE_URL)
  const companyId = employerProfileData?.company?.id || ''

  const handleCreateJobPostClicked = async () => {
    trackEvents(CLICK_ON_POST_NEW_JOB)
    if (!employerProfileData || !employerProfileData.company?.kycStatus) return
    const getFirstPageJobListingResp = await get(
      JOB_LISTING_URL(companyId, {
        page: 1,
      })
    )

    const firstPageJobListing: GetJobListingAPIResponse =
      getFirstPageJobListingResp.data

    if (
      (userKycPending({
        companyKyc: employerProfileData.company.kycStatus,
        employerKyc: employerProfileData.kycStatus,
      }) ||
        userKycRejected({
          companyKyc: employerProfileData.company.kycStatus,
          employerKyc: employerProfileData.kycStatus,
        })) &&
      firstPageJobListing.records.length > 0
    ) {
      dispatch(appActions.setModalName(MODAL_NAME.KYC_NOTICE_MODAL))
      return
    }
    if (
      userKycInReview({
        companyKyc: employerProfileData.company.kycStatus,
        employerKyc: employerProfileData.kycStatus,
      }) &&
      firstPageJobListing.records.length > 0
    ) {
      dispatch(appActions.setModalName(MODAL_NAME.KYC_INREVIEW_NOTIFY_MODAL))
      return
    }

    router.push(PATHS.CREATE_JOB)
  }

  return (
    <div className="relative flex flex-col-reverse justify-center rounded-xl bg-[#f4f6fb] px-4 py-10 lg:flex-col lg:px-20 lg:py-24">
      <div className="max-w-lg">
        <div className="mb-4">
          <H1>Ayo buat loker pertama</H1>
        </div>
        <div className="mb-10 lg:mb-4">
          <Caption>Halaman ini akan menampilkan daftar loker.</Caption>
        </div>
        <div className="flex md:max-w-[13rem] lg:block">
          <Button
            appearance="primary"
            type="button"
            onClick={handleCreateJobPostClicked}
            block
          >
            Buat loker baru
          </Button>
        </div>
      </div>
      <div className="mb-12 ml-auto max-w-full lg:absolute lg:right-20 lg:mb-0 lg:ml-0">
        <Image
          alt="img"
          src="/assets/images/img_no_jp.svg"
          width={393}
          height={286}
          objectFit="contain"
        />
      </div>
    </div>
  )
}

export default CreateJobHero
