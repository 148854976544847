import Image from 'next/image'
import React from 'react'

import { appActions } from '@features/app/reducers'

import Paragraph from '@components/typography/paragraph'

import { useAppDispatch } from '@hooks/redux'

import WhiteCloseIcon from '@common/icons/WhiteCloseIcon'

const FreeCoinsBanner = () => {
  const dispatch = useAppDispatch()
  const onClose = () => {
    dispatch(appActions.setIsFreeCoinsBannerOpen(false))
  }

  return (
    <div className="bg-[#39308C] px-3 py-4 mb-4 rounded-lg flex justify-between">
      <div className="flex items-center gap-x-2">
        <div>
          <Image
            src="/assets/icons/ic_free_coins_banner.svg"
            width={30}
            height={30}
            alt="Free coins"
          />
        </div>
        <div>
          <Paragraph weight="semibold" className="text-white text-2xl">
            Anda mendapatkan 50 Atma Coins gratis 🎉
          </Paragraph>
          <Paragraph className="font-thin text-sm text-white">
            Pelajari bagaimana menggunakan Atma Coins untuk mengakses fitur
            premium di website Atma Business untuk mempercepat proses rekrutmen
            Anda.
          </Paragraph>
        </div>
      </div>

      <div className="flex-1 flex justify-end gap-x-4 items-center">
        <a
          href="https://wp.atma.co.id/blog/atma-coins/"
          target="_blank"
          rel="noreferrer"
          className="font-thin text-white underline"
        >
          Pelajari lebih lengkap
        </a>
        <button type="button" onClick={onClose}>
          <WhiteCloseIcon />
        </button>
      </div>
    </div>
  )
}

export default FreeCoinsBanner
