import { useEffect, useState } from 'react'
import useSWR, { KeyedMutator } from 'swr'

import { JOB_DETAIL_URL } from '@constants/url'

import { JobDetailProps } from '../../types'

type UseJobDetailProps<Data> = {
  jobDetailData: Data | undefined
  isLoadingJobDetail: boolean
  mutateJobDetail: KeyedMutator<Data>
}

const useJobDetail = (jobId: string): UseJobDetailProps<JobDetailProps> => {
  const { data, error, mutate } = useSWR(jobId ? JOB_DETAIL_URL(jobId) : null)

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!data && !error) {
      setIsLoading(true)
    } else {
      setIsLoading(false)
    }
  }, [data, error])

  return {
    jobDetailData: data,
    isLoadingJobDetail: isLoading,
    mutateJobDetail: mutate,
  }
}

export default useJobDetail
