import React from 'react'
import { toast } from 'react-toastify'
import useSWRImmutable from 'swr/immutable'

import { appActions } from '@features/app/reducers'
import { GetEmployerProfileAPIResponseData } from '@features/kyc/types'
import { updateProfile } from '@features/profile/reducers'

import OnboardModal from '@components/modal/onboard-modal'

import { useAppDispatch, useAppSelector } from '@hooks/redux'

import MODAL_NAME from '@constants/modal-name'
import { EMPLOYERS_PROFILE_URL } from '@constants/url'

import errorFormat from '@utils/error-format'

const JobListingOnboardModal = () => {
  const modalName = useAppSelector((state) => state.appReducer.modalName)
  const dispatch = useAppDispatch()
  const { data: employerProfileData } =
    useSWRImmutable<GetEmployerProfileAPIResponseData>(EMPLOYERS_PROFILE_URL)

  const handleOnboardModalClicked = () => {
    if (!employerProfileData) return
    try {
      dispatch(appActions.setIsLoading(true))
      dispatch(
        updateProfile({
          hasViewedStartModal: true,
        })
      ).unwrap()
      dispatch(appActions.setIsLoading(false))
      dispatch(appActions.removeModal())
    } catch (err) {
      dispatch(appActions.setIsLoading(false))
      dispatch(appActions.removeModal())
      toast.error(errorFormat(err))
    }
  }

  React.useEffect(() => {
    if (employerProfileData?.hasViewedStartModal === false) {
      dispatch(appActions.setModalName(MODAL_NAME.ONBOARD_MODAL))
    }
  }, [employerProfileData, dispatch])

  return (
    <OnboardModal
      open={modalName === MODAL_NAME.ONBOARD_MODAL}
      onClose={handleOnboardModalClicked}
      onSubmit={handleOnboardModalClicked}
    />
  )
}

export default JobListingOnboardModal
