/* eslint-disable @typescript-eslint/no-shadow */
import { Popover, Transition } from '@headlessui/react'
import { isEmptyArray } from 'formik'
import React from 'react'

import { jobActions } from '@features/jobs/job-detail/reducers'

import Button from '@components/button'

import { useAppDispatch, useAppSelector } from '@hooks/redux'

import { JOBSTATUS_OPTIONS, JOB_STATUS_ENUM } from '@constants/job-statuses'

const JobListingStatusFilter = () => {
  const status = useAppSelector(
    (state) => state.jobReducer.jobListingQueries.status
  )

  const [selectedStatus, setSelectedStatus] = React.useState<string[]>(status)

  const allStatuses = JOBSTATUS_OPTIONS.flatMap((option) => option.value)
  const dispatch = useAppDispatch()

  const onSubmitStatusFilter = (selectedStatus: string[]) => {
    dispatch(jobActions.setJobListingQueries({ status: selectedStatus }))
  }

  const onCheckChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'Tampilkan semua' && e.target.checked) {
      setSelectedStatus(JOBSTATUS_OPTIONS.flatMap((item) => item.value))
    } else {
      const option = JOBSTATUS_OPTIONS.find(
        (item) => item.name === e.target.name
      )

      if (!option) {
        setSelectedStatus(selectedStatus.length < 1 ? selectedStatus : [])
        return
      }

      if (isEmptyArray(e.target.value)) {
        setSelectedStatus(e.target.checked ? allStatuses : [])
      }
      if (e.target.checked) {
        setSelectedStatus((prevState) => [
          ...prevState,
          ...option.value.filter((val) => !prevState.includes(val)),
        ])
      } else {
        setSelectedStatus((prevState) =>
          prevState.filter(
            (val) => !option.value.includes(val as JOB_STATUS_ENUM)
          )
        )
      }
    }
  }

  const showSelectedStatus = (() => {
    if (
      selectedStatus.length === allStatuses.length ||
      selectedStatus.length === 0
    ) {
      return 'Tampilkan semua'
    }

    return JOBSTATUS_OPTIONS.filter((option) =>
      option.value?.every((val) => selectedStatus.includes(val))
    )
      .map((option) => option.name)
      .join(', ')
  })()

  return (
    <div className="max-w-sm">
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={` ${open ? '' : 'text-opacity-90'} group block rounded-md bg-transparent text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 hover:text-opacity-100 lg:inline-flex lg:items-center lg:space-x-2`}
            >
              <p className="text-base text-primary-gray">Status: </p>
              <p className="rounded bg-neutral-gray-50 px-2 py-1 font-semibold text-primary-black">
                {showSelectedStatus}
              </p>
            </Popover.Button>
            <Transition
              as={React.Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute left-36 z-10 mt-3 w-60 max-w-sm -translate-x-1/2 transform rounded-lg border border-primary-black sm:px-0 lg:max-w-3xl">
                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="relative bg-white py-2">
                    {[...JOBSTATUS_OPTIONS].map((item) => (
                      <button
                        key={item.name}
                        type="button"
                        className="-my-2 mx-auto mb-2 flex w-full items-stretch px-4 py-3 transition duration-150 ease-in-out focus:outline-none focus-visible:ring focus-visible:ring-primary-blue focus-visible:ring-opacity-50 hover:bg-[#F4F6FB]"
                      >
                        <div className="flex w-full items-center justify-between">
                          <p className="text-sm text-primary-black">
                            {item.name}
                          </p>
                          <input
                            type="checkbox"
                            className="h-5 w-5 rounded border-2 border-primary-gray outline-none hover:cursor-pointer"
                            checked={
                              item.value.length === 0
                                ? selectedStatus.length === allStatuses.length
                                : item.value.every((val) =>
                                    selectedStatus.includes(val)
                                  )
                            }
                            onChange={onCheckChange}
                            name={item.name}
                            value={item.value.length === 0 ? 'All' : item.name}
                          />
                        </div>
                      </button>
                    ))}
                    <div className="flex justify-center px-4">
                      <Button
                        type="button"
                        appearance="primary"
                        onClick={() => {
                          onSubmitStatusFilter(selectedStatus)
                          close()
                        }}
                        block
                      >
                        Terapkan
                      </Button>
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  )
}

export default JobListingStatusFilter
