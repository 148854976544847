import { post } from '@helpers/fetch-wrapper'
import { formatNumbersWithSeparator } from '@helpers/formatter'
import React from 'react'
import { toast } from 'react-toastify'

import CoinsTopupDrawer from '@features/coins/components/coins-topup-drawer'

import {
  COINS_CLICK_PAY_BUTTON,
  COINS_REDIRECTED_TO_PAYMENT_METHOD_PAGE,
} from '@constants/event-tracking'
import { TOPUP_PAYMENT_URL } from '@constants/url'

import errorFormat from '@utils/error-format'
import trackEvents from '@utils/track-events'

import { useCoinsActions } from '@store/coins/slice'

import { TopupPackage } from '@custom-types/coins'

const TopupCoinsJobModal = () => {
  const { setTopupDrawerState } = useCoinsActions()

  const handleSubmitPayment = async (topupPackage: TopupPackage) => {
    setTopupDrawerState({
      loadingPayment: true,
    })
    trackEvents(COINS_CLICK_PAY_BUTTON, {
      topup_amount: `IDR${formatNumbersWithSeparator(topupPackage.realAmount)}`,
    })
    try {
      const response = await post(TOPUP_PAYMENT_URL, {
        packageId: topupPackage.id,
      })
      setTopupDrawerState({
        isSubmitted: true,
      })
      const responseData: { paymentTransactionId: string; paymentURL: string } =
        response.data

      trackEvents(COINS_REDIRECTED_TO_PAYMENT_METHOD_PAGE)
      window.open(responseData.paymentURL, '_blank')
    } catch (e) {
      toast.error(errorFormat(e))
    } finally {
      setTopupDrawerState({
        loadingPayment: false,
      })
    }
  }
  return <CoinsTopupDrawer onSubmitPayment={handleSubmitPayment} />
}

export default TopupCoinsJobModal
