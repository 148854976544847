import Image from 'next/image'
import React from 'react'

import Button from '@components/button'
import Caption from '@components/typography/caption'
import H1 from '@components/typography/heading-one'

const ErrorHero: React.FC = () => (
  <div className="relative flex flex-col-reverse justify-center rounded-xl bg-[#F7F6F2] px-4 py-10 lg:flex-col lg:px-20 lg:py-24">
    <div className="max-w-lg">
      <div className="mb-4">
        <H1>Kesalahan bukan terjadi pada layar Anda</H1>
      </div>
      <div className="mb-10 lg:mb-4">
        <Caption>
          Tenang, cek koneksi internet atau Wi-Fi kamu dan coba lagi ya
        </Caption>
      </div>
      <div className="flex md:max-w-[13rem] lg:block">
        <Button
          appearance="primary"
          type="button"
          onClick={() => window.location.reload()}
          block
        >
          Coba lagi
        </Button>
      </div>
    </div>
    <div className="mb-12 ml-auto max-w-full lg:absolute lg:right-20 lg:mb-0 lg:ml-0">
      <Image
        alt="icon"
        src="/assets/images/img_error_hero.svg"
        width={340}
        height={288}
        objectFit="contain"
      />
    </div>
  </div>
)

export default ErrorHero
