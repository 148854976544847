import React from 'react'

import { appActions } from '@features/app/reducers'

import QuestionModal from '@components/modal/question-modal'

import { useAppDispatch, useAppSelector } from '@hooks/redux'

import {
  COINS_CLICK_TOPUP_BUTTON,
  COINS_DEDUCTION_CANCELATION,
} from '@constants/event-tracking'
import MODAL_NAME from '@constants/modal-name'

import trackEvents from '@utils/track-events'

import { useCoinsActions } from '@store/coins/slice'

const UnsufficientBalanceModal = () => {
  const { modalName } = useAppSelector((state) => state.appReducer)
  const dispatch = useAppDispatch()
  const { productCode } = useAppSelector((state) => state.coins)

  const { setTopupDrawerState } = useCoinsActions()

  return (
    <QuestionModal
      open={modalName === MODAL_NAME.NOT_ENOUGH_COIN}
      title="Saldo Atma Coins tidak cukup. Segera lakukan top up untuk melanjutkan"
      onClose={() => {
        trackEvents(COINS_DEDUCTION_CANCELATION, {
          product: productCode,
        })
        dispatch(appActions.removeModal())
      }}
      onSubmit={() => {
        dispatch(appActions.removeModal())
        trackEvents(COINS_CLICK_TOPUP_BUTTON, {
          from: 'Insufficient Funds Popup',
        })
        setTimeout(() => {
          setTopupDrawerState({ open: true })
        }, 500)
      }}
      buttonTextRight="Top Up"
    />
  )
}

export default UnsufficientBalanceModal
