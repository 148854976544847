import React, { ReactNode } from 'react'

type Props = {
  children: ReactNode
  weight?: 'normal' | 'medium' | 'bold' | 'semibold'
  color?: string
}

const H3: React.FC<Props> = ({
  children,
  weight = 'medium',
  color = 'text-primary-black',
}: Props) => {
  const className = `text-xl font-${weight} ${color}`

  return <h3 className={className}>{children}</h3>
}

export default H3
