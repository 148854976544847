import React from 'react'
import { toast } from 'react-toastify'
import useSWRImmutable from 'swr/immutable'

import { appActions } from '@features/app/reducers'
import { jobListingQueriesToServerRequest } from '@features/jobs/helpers'
import useJobListingQuery from '@features/jobs/hooks/useJobListingQuery'
import {
  duplicateExpiredJob,
  jobActions,
} from '@features/jobs/job-detail/reducers'
import { GetEmployerProfileAPIResponseData } from '@features/kyc/types'

import RepostModal from '@components/modal/repost-modal'

import { useAppDispatch, useAppSelector } from '@hooks/redux'

import { REPOSTED_JOB } from '@constants/event-tracking'
import MODAL_NAME from '@constants/modal-name'
import { EMPLOYERS_PROFILE_URL } from '@constants/url'

import errorFormat from '@utils/error-format'
import trackEvents from '@utils/track-events'

const RepostJobModal = () => {
  const dispatch = useAppDispatch()
  const modalJobId = useAppSelector((state) => state.jobReducer.modalId)
  const modalName = useAppSelector((state) => state.appReducer.modalName)
  const jobListingQueries = useAppSelector(
    (state) => state.jobReducer.jobListingQueries
  )

  const { data: employerProfileData } =
    useSWRImmutable<GetEmployerProfileAPIResponseData>(EMPLOYERS_PROFILE_URL)
  const companyId = employerProfileData?.company?.id || ''

  const { mutate } = useJobListingQuery({
    companyId,
    query: jobListingQueriesToServerRequest(jobListingQueries),
  })

  const reRegisterJob = async () => {
    try {
      await dispatch(duplicateExpiredJob(modalJobId)).unwrap()

      dispatch(appActions.removeModal())
      mutate()

      dispatch(jobActions.changeNewPostHighlight('border-[#fcdf9a]'))

      trackEvents(REPOSTED_JOB)
      toast('Repost job berhasil', {
        hideProgressBar: true,
        style: {
          backgroundColor: '#52B788',
          color: '#FFFFFF',
        },
      })
    } catch (reRegisterError) {
      toast.error(errorFormat(reRegisterError))
    }
  }
  return (
    <RepostModal
      open={modalName === MODAL_NAME.REPOST_MODAL}
      onClose={() => dispatch(appActions.removeModal())}
      onSubmit={reRegisterJob}
    />
  )
}

export default RepostJobModal
