/* eslint-disable @typescript-eslint/no-shadow */
import React from 'react'
import { toast } from 'react-toastify'
import useSWRImmutable from 'swr/immutable'

import { appActions } from '@features/app/reducers'
import { jobListingQueriesToServerRequest } from '@features/jobs/helpers'
import useJobListingQuery from '@features/jobs/hooks/useJobListingQuery'
import Slider from '@features/jobs/job-detail/components/slider'
import {
  updateJobDetail,
  useJobsActions,
} from '@features/jobs/job-detail/reducers/JobReducer'
import { userKycInReview, userKycRejected } from '@features/kyc/helpers'
import {
  GetEmployerProfileAPIResponseData,
  KycVerificationStatus,
} from '@features/kyc/types'

import { useAppDispatch, useAppSelector } from '@hooks/redux'

import {
  JOB_SEARCH_ACTIVATE_JOB_LISTING,
  JOB_SEARCH_DEACTIVATE_JOB_LISTING,
} from '@constants/event-tracking'
import { JobStatus } from '@constants/job-statuses'
import MODAL_NAME from '@constants/modal-name'
import { EMPLOYERS_PROFILE_URL } from '@constants/url'

import errorFormat from '@utils/error-format'
import trackEvents from '@utils/track-events'

const JobListingDetailDrawer = () => {
  const jobId = useAppSelector(
    (state) => state.jobReducer.jobDetailDrawer.jobId
  )

  const jobListingQueries = useAppSelector(
    (state) => state.jobReducer.jobListingQueries
  )
  const dispatch = useAppDispatch()
  const { data: employerProfileData } =
    useSWRImmutable<GetEmployerProfileAPIResponseData>(EMPLOYERS_PROFILE_URL)
  const companyId = employerProfileData?.company?.id || ''

  const open = useAppSelector((state) => state.jobReducer.jobDetailDrawer.open)
  const { resetJobDetailDrawerState } = useJobsActions()

  const { mutate } = useJobListingQuery({
    companyId,
    query: jobListingQueriesToServerRequest(jobListingQueries),
  })

  const changeJobStatus = async (jobId: string, currentStatus: JobStatus) => {
    if (!employerProfileData) return
    if (
      userKycRejected({
        employerKyc: employerProfileData?.kycStatus as KycVerificationStatus,
        companyKyc: employerProfileData.company
          ?.kycStatus as KycVerificationStatus,
      })
    ) {
      dispatch(appActions.setModalName(MODAL_NAME.KYC_REVERIFY_REJECTED_MODAL))
      return
    }

    if (
      userKycInReview({
        employerKyc: employerProfileData?.kycStatus as KycVerificationStatus,
        companyKyc: employerProfileData?.company
          ?.kycStatus as KycVerificationStatus,
      })
    ) {
      dispatch(appActions.setModalName(MODAL_NAME.KYC_INREVIEW_NOTIFY_MODAL))
      return
    }

    const isJobActive = currentStatus === 'Active'

    try {
      await dispatch(
        updateJobDetail({
          jobId,
          isActive: !isJobActive,
        })
      ).unwrap()

      if (isJobActive) {
        trackEvents(JOB_SEARCH_DEACTIVATE_JOB_LISTING)
      } else {
        trackEvents(JOB_SEARCH_ACTIVATE_JOB_LISTING)
      }

      mutate()
      // mutateCandidateSearchJobPost()
      dispatch(appActions.removeModal())
    } catch (changeJobError) {
      toast.error(errorFormat(changeJobError))
    }
  }

  return (
    <Slider
      id={jobId}
      isOpen={open}
      onClose={() => {
        resetJobDetailDrawerState()
      }}
      changeJobStatus={changeJobStatus}
    />
  )
}

export default JobListingDetailDrawer
