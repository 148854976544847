import { Popover, Transition } from '@headlessui/react'
import React from 'react'

import { jobActions } from '@features/jobs/job-detail/reducers'

import { useAppDispatch, useAppSelector } from '@hooks/redux'

import { jobOrder } from '@constants/job-order'

const SORT_OPTION: Array<{
  name: string
  value: 'Latest' | 'Oldest' | 'ApplicantCount'
}> = [
  {
    name: 'Terbaru',
    value: 'Latest',
  },
  {
    name: 'Peminat terbanyak',
    value: 'ApplicantCount',
  },
  {
    name: 'Paling lama',
    value: 'Oldest',
  },
]

const JobListingSort = () => {
  const order = useAppSelector(
    (state) => state.jobReducer.jobListingQueries.order
  )
  const dispatch = useAppDispatch()

  const onSubmitOrderFilter = (
    selectedOrder: 'Latest' | 'Oldest' | 'ApplicantCount'
  ) => {
    dispatch(jobActions.setJobListingQueries({ order: selectedOrder }))
  }

  return (
    <div className="max-w-sm">
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={` ${open ? '' : 'text-opacity-90'} group block rounded-md bg-transparent text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 hover:text-opacity-100 lg:inline-flex lg:items-center lg:space-x-2`}
            >
              <p className="text-base text-primary-gray">Urutkan: </p>
              <p className="text-primary-black">{jobOrder[order]}</p>
            </Popover.Button>
            <Transition
              as={React.Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute right-1 z-10 mt-3 w-52 max-w-sm transform rounded-lg border border-primary-black sm:px-0 lg:max-w-3xl">
                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="relative bg-white py-2">
                    {SORT_OPTION.map((item) => (
                      <button
                        key={item.name}
                        type="button"
                        className="-my-2 mx-auto mb-2 flex w-full items-stretch px-4 py-3 transition duration-150 ease-in-out focus:outline-none focus-visible:ring focus-visible:ring-primary-blue focus-visible:ring-opacity-50 hover:bg-[#F4F6FB]"
                        onClick={() => {
                          onSubmitOrderFilter(item.value)
                          close()
                        }}
                      >
                        <div className="flex items-center">
                          <p className="text-sm text-primary-black">
                            {item.name}
                          </p>
                        </div>
                      </button>
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  )
}

export default JobListingSort
