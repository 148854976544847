import Image from 'next/image'
import React from 'react'

import Button from '@components/button'
import Caption from '@components/typography/caption'
import H1 from '@components/typography/heading-one/H1'

import { ATMA_CS_PHONE_NUMBER } from '@constants/phone-number'
import shareToWhatsApp from '@constants/whatsapp-url'

const SuspendedHero = () => (
  <div className="relative flex flex-col-reverse justify-center rounded-xl bg-[#F4F6FB] px-4 py-10 lg:flex-col lg:px-20 lg:py-24">
    <div className="max-w-lg">
      <div className="mb-4">
        <H1>Akses dibatasi</H1>
      </div>
      <div className="mb-10 lg:mb-4">
        <Caption>
          Akun Anda telah ditangguhkan karena laporan dari beberapa pencari
          kerja. Silakan hubungi Customer Service
        </Caption>
      </div>
      <div className="flex md:max-w-[13rem] lg:block">
        <Button
          appearance="primary"
          type="button"
          onClick={() =>
            window.open(shareToWhatsApp('+62', ATMA_CS_PHONE_NUMBER), '_blank')
          }
          block
        >
          Hubungi CS
        </Button>
      </div>
    </div>
    <div className="mb-12 ml-auto max-w-full lg:absolute lg:right-20 lg:mb-0 lg:ml-0">
      <Image
        alt="icon"
        src="/assets/images/img_no_jp.svg"
        width={393}
        height={286}
        objectFit="contain"
      />
    </div>
  </div>
)

export default SuspendedHero
