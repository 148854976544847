import { Popover, Switch, Transition } from '@headlessui/react'
import { get, post } from '@helpers/fetch-wrapper'
import { differenceInDays, formatDistanceToNow } from 'date-fns'
import localeId from 'date-fns/locale/id'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'
import { toast } from 'react-toastify'
import useSWRImmutable from 'swr/immutable'

import { appActions } from '@features/app/reducers'
import { useApplicationsActions } from '@features/applications/reducers/ApplicationsReducer'
import usePriceList from '@features/coins/hooks/usePriceList'
import { jobListingQueriesToServerRequest } from '@features/jobs/helpers'
import useJobListingQuery from '@features/jobs/hooks/useJobListingQuery'
import { jobActions, updateJobDetail } from '@features/jobs/job-detail/reducers'
import { useJobsActions } from '@features/jobs/job-detail/reducers/JobReducer'
import {
  userKycInReview,
  userKycPending,
  userKycRejected,
} from '@features/kyc/helpers'
import {
  GetEmployerProfileAPIResponseData,
  KycVerificationStatus,
} from '@features/kyc/types'

import Button from '@components/button'

import { useAppDispatch, useAppSelector } from '@hooks/redux'

import { EMPLOYMENT_TYPE_OPTIONS } from '@constants/employment-type'
import {
  BOOST_CLICK_BOOST_BUTTON_JOB_SCREEN,
  CLICK_ON_REPOST_JOB,
  COINS_DEDUCTION_ATTEMPT,
  COINS_DEDUCTION_FAILED,
  COINS_DEDUCTION_INSUFFICIENT_BALANCE,
  JOB_SEARCH_ACTIVATE_JOB_LISTING,
  JOB_SEARCH_CLICK_CARI_KANDIDAT,
  JOB_SEARCH_DEACTIVATE_JOB_LISTING,
} from '@constants/event-tracking'
import { JobStatus } from '@constants/job-statuses'
import MODAL_NAME from '@constants/modal-name'
import { PATHS } from '@constants/paths'
import {
  COINS_BALANCE_URL,
  EMPLOYERS_PROFILE_URL,
  INITIALIZE_URL,
} from '@constants/url'

import errorFormat from '@utils/error-format'
import trackEvents from '@utils/track-events'

import { useCoinsActions } from '@store/coins/slice'

import { JobContentProps, JobListingCardProps } from './types'

const JobContent = ({
  title,
  content,
  jobInactive = false,
  lastChild = false,
}: JobContentProps) => (
  <div className={`flex-1 ${lastChild && 'col-span-2'}`}>
    <p className="mb-1 text-xs text-secondary-gray">{title}</p>
    <p
      className={`text-sm ${
        jobInactive ? 'text-secondary-gray' : 'text-primary-black'
      }`}
    >
      {content}
    </p>
  </div>
)

const JobListingCard = ({ jobPostData, index }: JobListingCardProps) => {
  const {
    id,
    title,
    employmentType,
    requiredExperienceInYears,
    minimumEducationRequired,
    requiredGender,
    openings,
    status,
    expiryAt,
    createdAt,
    canRepost,
    isBoosted,
    preScreeningChatChannelCount,
    location,
    minimumAge,
    maximumAge,
    minimumSalary,
    maximumSalary,
  } = jobPostData

  const { data: initializeData } = useSWRImmutable<{
    accessControl: {
      canBoost: boolean
    }
  }>(INITIALIZE_URL, async (url: string) => {
    const response = await post(url)
    return response.data
  })

  const jobsActions = useJobsActions()
  const dispatch = useAppDispatch()
  const router = useRouter()
  const { setJobApplicantsPopupFilters } = useApplicationsActions()
  const newPostHighlight = useAppSelector(
    (state) => state.jobReducer.newPostHighlight
  )

  const jobListingQueries = useAppSelector(
    (state) => state.jobReducer.jobListingQueries
  )
  const [isPopoverShown, setIsPopoverShown] = React.useState<boolean>(false)
  const expiredDate = differenceInDays(new Date(expiryAt), new Date())

  const { data: employerProfileData } =
    useSWRImmutable<GetEmployerProfileAPIResponseData>(EMPLOYERS_PROFILE_URL)
  const companyId = employerProfileData?.company?.id || ''

  const { priceListData } = usePriceList()
  const { setProductCode } = useCoinsActions()
  const productCode = 'JobPost:Repost'
  const repostJobPostPrice =
    priceListData?.find(
      (product) => product.productCode === 'JobPost:Repost' && product.isVisible
    )?.price || null

  const { mutate } = useJobListingQuery({
    companyId,
    query: jobListingQueriesToServerRequest(jobListingQueries),
  })

  const changeJobStatus = async (jobId: string, currentStatus: JobStatus) => {
    const isJobActive = currentStatus === 'Active'

    try {
      await dispatch(
        updateJobDetail({
          jobId,
          isActive: !isJobActive,
        })
      ).unwrap()

      if (isJobActive) {
        trackEvents(JOB_SEARCH_DEACTIVATE_JOB_LISTING)
      } else {
        trackEvents(JOB_SEARCH_ACTIVATE_JOB_LISTING)
      }

      mutate()
      dispatch(appActions.removeModal())
    } catch (changeJobError) {
      toast.error(errorFormat(changeJobError))
    }
  }

  const renderGender = () => {
    let str = ''

    switch (requiredGender) {
      case 'Both':
        str = 'Keduanya'
        break
      case 'Male':
        str = 'Pria'
        break
      case 'Female':
        str = 'Wanita'
        break
      default:
        break
    }

    return str
  }

  const renderStatus = () => {
    let str = ''

    if (expiredDate < 0) {
      str = 'Anda dapat mendaftarkan kembali'
    }

    switch (status) {
      case 'Active':
      case 'InActive':
        str = ''
        break
      case 'Pending':
        str = ''
        break
      case 'Rejected':
        str = 'Gagal untuk lolos tahap verifikasi tim Atma'
        break
      case 'Expired':
        str = 'Anda dapat mendaftarkan kembali'
        break
      default:
        break
    }

    if (expiredDate === 0 && status === 'Active') {
      str = 'Akan kadaluarsa hari ini'
    }

    return (
      <div className="relative flex space-x-2">
        {status === 'Rejected' && (
          <Image
            alt="icon"
            src="/assets/icons/ic_job_expired.svg"
            width={14}
            height={14}
            onMouseEnter={() => setIsPopoverShown(true)}
            onMouseLeave={() => setIsPopoverShown(false)}
          />
        )}
        <span className="text-xs text-primary-gray">{str}</span>
        {status === 'Rejected' && (
          <Popover className="absolute -left-3 -top-36 w-full">
            <Transition
              as={React.Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
              show={isPopoverShown}
            >
              <Popover.Panel className="relative left-0 z-10 mt-3 sm:px-0">
                <div className="overflow-hidden rounded-lg shadow-lg shadow-slate-400/70">
                  <div className="relative w-full bg-white p-3">
                    <span className="text-xs text-primary-black">
                      Harap menghubungi Customer Support kami untuk informasi
                      lebih lanjut
                    </span>
                  </div>
                </div>
                <div className="arrow-down absolute -translate-y-1 transform" />
              </Popover.Panel>
            </Transition>
          </Popover>
        )}
      </div>
    )
  }

  const renderJobActions = () => {
    if (status === 'Active' || status === 'InActive' || status === 'Pending') {
      return (
        <Switch
          checked={status === 'Active'}
          onChange={() => {
            if (!employerProfileData || !employerProfileData.company) return
            if (
              userKycPending({
                companyKyc: employerProfileData.company.kycStatus,
                employerKyc: employerProfileData.kycStatus,
              }) ||
              userKycRejected({
                companyKyc: employerProfileData.company.kycStatus,
                employerKyc: employerProfileData.kycStatus,
              })
            ) {
              dispatch(appActions.setModalName(MODAL_NAME.KYC_NOTICE_MODAL))
              return
            }
            if (
              userKycInReview({
                companyKyc: employerProfileData.company.kycStatus,
                employerKyc: employerProfileData.kycStatus,
              })
            ) {
              dispatch(
                appActions.setModalName(MODAL_NAME.KYC_INREVIEW_NOTIFY_MODAL)
              )
              return
            }
            if (status === 'Active') {
              dispatch(appActions.setModalName(MODAL_NAME.QUESTION_MODAL))
              dispatch(jobActions.setModalId(id))
            }
            if (status !== 'Active') {
              changeJobStatus(id, status)
            }
          }}
          className={`${status === 'Active' ? 'bg-[#52B788]' : 'bg-gray-200'} relative inline-flex h-[28px] w-[55px] flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={`${
              status === 'Active' ? 'translate-x-8' : 'translate-x-0'
            } pointer-events-none inline-block h-[22px] w-[22px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
          />
        </Switch>
      )
    }

    if (status === 'Expired' && expiredDate <= 0 && canRepost) {
      return (
        <Button
          type="button"
          appearance="link"
          onClick={async (event) => {
            event.preventDefault()
            trackEvents(CLICK_ON_REPOST_JOB)
            if (repostJobPostPrice !== null) {
              const getCoinsBalanceResponse = await get(COINS_BALANCE_URL)
              trackEvents(COINS_DEDUCTION_ATTEMPT, {
                product: productCode,
              })
              const coinsBalanceData: { balance: number } =
                getCoinsBalanceResponse.data

              if (coinsBalanceData.balance < repostJobPostPrice) {
                dispatch(appActions.setModalName(MODAL_NAME.NOT_ENOUGH_COIN))
                setProductCode(productCode)
                trackEvents(COINS_DEDUCTION_INSUFFICIENT_BALANCE, {
                  product: productCode,
                })
                trackEvents(COINS_DEDUCTION_FAILED, {
                  product: productCode,
                })
                return
              }
              dispatch(jobActions.setModalId(id))
              dispatch(appActions.setModalName(MODAL_NAME.CHARGE_REPOST_JOB))
              return
            }

            dispatch(appActions.setModalName(MODAL_NAME.REPOST_MODAL))
            dispatch(jobActions.setModalId(id))
          }}
          className="underline"
        >
          Tayangkan lagi
        </Button>
      )
    }
    return null
  }

  return (
    <Link href={`/applications?jid=${id}`} passHref>
      <div
        className={`relative z-0 mb-4 rounded-lg border-2 border-border-gray md:mb-0 ${
          status === 'Rejected'
            ? 'cursor-not-allowed bg-sidebar-gray'
            : 'cursor-pointer bg-white hover:border-primary-blue'
        } ${router?.query?.repost && index === 0 ? newPostHighlight : ''}`}
        role="button"
        tabIndex={0}
        aria-hidden="true"
      >
        <div className="border-b-2 p-4">
          <div className="mb-4 flex justify-between gap-x-4">
            <p
              className="line-clamp-2 h-[56px] flex-1 flex-[0.7] text-lg font-medium"
              title={title}
            >
              {title}
            </p>

            <Button
              type="button"
              appearance="link"
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()

                jobsActions.setJobDetailDrawerState({
                  open: true,
                  jobId: jobPostData.id,
                })
              }}
              className="relative z-[10] flex-[0.3] justify-end self-baseline p-0 underline"
            >
              Lihat detail
            </Button>
          </div>
          <div className="mb-4 grid grid-cols-3 gap-x-3 gap-y-4">
            <JobContent
              title="Tipe"
              content={
                EMPLOYMENT_TYPE_OPTIONS.find(
                  (opt) => opt.value === employmentType
                )?.label as string
              }
              jobInactive={status === 'Expired'}
            />
            <JobContent
              title="Pengalaman"
              content={`${
                requiredExperienceInYears < 1 ? `<1` : requiredExperienceInYears
              } tahun`}
              jobInactive={status === 'Expired'}
            />
            <JobContent
              title="Pendidikan"
              content={minimumEducationRequired?.degreeName}
              jobInactive={status === 'Expired'}
            />
            <JobContent
              title="Jenis Kelamin"
              content={renderGender()}
              jobInactive={status === 'Expired'}
            />
            <JobContent
              title="Kebutuhan Karyawan"
              content={`${openings} Karyawan`}
              jobInactive={status === 'Expired'}
              lastChild
            />
          </div>
          <div
            className={`flex rounded-lg px-0 py-2 ${
              status === 'Rejected' ? 'bg-transparent' : 'bg-[#f4f6fb]'
            }`}
          >
            <div
              role="button"
              className="m-0 w-full p-0"
              tabIndex={0}
              aria-hidden="true"
              onClick={(e) => {
                if (status === 'Pending' || status === 'Rejected') {
                  e.preventDefault()
                }
              }}
            >
              <div className="flex items-center justify-between pl-2.5">
                <div>
                  <p className="mb-1 text-xs text-primary-gray">
                    Pelamar belum diproses
                  </p>
                  <div>
                    <p
                      className={`${
                        status === 'InActive' ||
                        status === 'Rejected' ||
                        status === 'Pending'
                          ? 'text-secondary-gray'
                          : 'text-primary-black'
                      }`}
                    >
                      {preScreeningChatChannelCount !== 0
                        ? preScreeningChatChannelCount
                        : 'Belum ada'}{' '}
                      Pelamar
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex">
            <Button
              type="button"
              appearance="outline"
              className="mt-4 w-full items-center justify-between !gap-x-[4px] !space-x-[0px] p-[12px]"
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                if (status !== 'Active') {
                  dispatch(
                    appActions.setModalName(
                      MODAL_NAME.CANDIDATE_SEARCH_NOTICE_MODAL
                    )
                  )
                } else {
                  trackEvents(JOB_SEARCH_CLICK_CARI_KANDIDAT, {
                    entry_point: 'Loker',
                  })

                  setJobApplicantsPopupFilters({
                    genders:
                      requiredGender !== 'Both' && requiredGender != null
                        ? requiredGender
                        : undefined,
                    homeAddresses: location?.shortAddress
                      ? [
                          {
                            cityName:
                              (location?.shortAddress as string) ?? undefined,
                          },
                        ]
                      : undefined,
                    minAge: minimumAge ? Number(minimumAge) : undefined,
                    maxAge: maximumAge ? Number(maximumAge) : undefined,
                    minSalary: minimumSalary ?? undefined,
                    maxSalary: maximumSalary ?? undefined,
                  })

                  router.push({
                    pathname: PATHS.SEARCH_CANDIDATE,
                    query: {
                      jid: id,
                    },
                  })
                }
              }}
            >
              <span>Cari kandidat</span>
              <div className="relative h-[2rem] w-[2rem]">
                <Image
                  alt="icon"
                  src="/assets/icons/ic_candidate_search.svg"
                  layout="fill"
                />
              </div>
            </Button>
            {initializeData && initializeData.accessControl.canBoost && (
              <Button
                type="button"
                appearance="outline"
                className="ml-2 mt-4 w-full items-center justify-between !gap-x-[4px] !space-x-[0px] p-[12px]"
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  if (status !== 'Active') {
                    dispatch(
                      appActions.setModalName(MODAL_NAME.BOOST_NOTICE_MODAL)
                    )
                    return
                  }

                  if (
                    userKycInReview({
                      employerKyc:
                        employerProfileData?.kycStatus as KycVerificationStatus,
                      companyKyc: employerProfileData?.company
                        ?.kycStatus as KycVerificationStatus,
                    })
                  ) {
                    dispatch(
                      appActions.setModalName(
                        MODAL_NAME.KYC_INREVIEW_NOTIFY_MODAL
                      )
                    )
                  } else if (
                    userKycRejected({
                      employerKyc:
                        employerProfileData?.kycStatus as KycVerificationStatus,
                      companyKyc: employerProfileData?.company
                        ?.kycStatus as KycVerificationStatus,
                    })
                  ) {
                    dispatch(
                      appActions.setModalName(
                        MODAL_NAME.KYC_REVERIFY_REJECTED_MODAL
                      )
                    )
                  } else {
                    trackEvents(BOOST_CLICK_BOOST_BUTTON_JOB_SCREEN)
                    router.push({
                      pathname: '/boost',
                      query: {
                        jid: id,
                      },
                    })
                  }
                }}
              >
                <span>Boost</span>
                <div className="relative h-[2rem] w-[2rem]">
                  <Image
                    src="/assets/icons/ic_boost.svg"
                    layout="fill"
                    alt="icon"
                  />
                </div>
              </Button>
            )}
          </div>
          <p className="pt-[16px] text-sm text-primary-gray">
            Diterbitkan{' '}
            {formatDistanceToNow(new Date(createdAt), {
              locale: localeId,
            })}{' '}
            lalu
          </p>
        </div>
        <div className="flex items-start justify-between p-4">
          <div className="flex flex-col items-start space-y-2">
            <div className="flex space-x-2">
              <JobBadge status={status} />
              <JobBoostedBadge isBoosted={isBoosted} />
            </div>
            {renderStatus()}
          </div>
          {renderJobActions()}
        </div>
      </div>
    </Link>
  )
}

const JobBoostedBadge: React.FC<{ isBoosted: boolean }> = ({ isBoosted }) =>
  isBoosted ? (
    <div className="rounded-full bg-[#007AFF] px-3 py-[2px] text-[#FFFFFF]">
      <span>Boosted</span>
    </div>
  ) : null

const JobBadge = ({ status }: { status: string }) => {
  let classname = 'px-3 py-[2px] rounded-full'
  let str = ''

  switch (status) {
    case 'Active':
      classname += ' bg-[#D8F3DC] text-[#40916C]'
      str = 'Aktif'
      break
    case 'Expired':
      classname += ' bg-[#FFE3E0] text-[#CC444B]'
      str = 'Kadaluarsa'
      break
    case 'InActive':
      classname += ' bg-[#E1E1E1] text-primary-black'
      str = 'Non-aktif'
      break
    case 'Pending':
      classname += ' bg-[#E1E1E1] text-primary-black'
      str = 'Non-aktif'
      break
    case 'Rejected':
      classname += ' bg-[#FF3B30] text-white'
      str = 'Ditolak'
      break
    default:
      break
  }

  return (
    <div className={classname}>
      <span>{str}</span>
    </div>
  )
}

export default JobListingCard
