import { post } from '@helpers/fetch-wrapper'
import React from 'react'

import { appActions } from '@features/app/reducers'
import useTransactionDetail from '@features/coins/hooks/useTransactionDetail'

import Button from '@components/button/Button'
import Modal from '@components/modal'

import { useAppDispatch } from '@hooks/redux'

import { TRACK_URL } from '@constants/url'

import { formatDateInID } from '@utils/date-calculation'

import CoinIcon from '@common/icons/CoinIcon'

type Props = {
  isOpen: boolean
  transactionId: string
}

const FreeCoinsModal = (props: Props) => {
  const { isOpen, transactionId } = props
  const dispatch = useAppDispatch()
  const onClose = () => {
    post(TRACK_URL, {
      batch: [
        {
          entityType: 'WelcomeCoinTransaction',
          action: 'View',
          entityId: null,
          pageName: 'CoinTransactionFragment',
        },
      ],
    })
    dispatch(appActions.setIsFreeCoinsModalOpen(false))
  }
  const { transactionDetailData } = useTransactionDetail(transactionId)

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      title="Hadiah buat Anda!"
      className="w-[30rem] px-4 py-4"
    >
      <div className="flex justify-center mx-4 mt-4 mb-3">
        <CoinIcon />
      </div>
      <div className="border-neutral-gray-100 border-[1px] p-3 rounded-md mb-8">
        <p className="text-primary-black font-semibold text-center">
          Bonus Atma Coins gratis
        </p>
        <div className="grid grid-cols-2 gap-y-4 items-center">
          <span className="text-xs text-primary-gray">
            {transactionDetailData?.createdAt &&
              formatDateInID(transactionDetailData?.createdAt)}
          </span>
          <span className="w-fit rounded-3xl text-xs px-2 py-1 text-green-700 bg-hired-0 ml-auto">
            {transactionDetailData?.status === 'Success' && 'Sukses'}
          </span>{' '}
          <span className="text-xs text-primary-gray">Atma Coins didapat</span>
          <span className="flex items-center justify-end">
            <CoinIcon width={12} height={12} />
            <p className="text-primary-black font-semibold ml-1">
              {' '}
              {transactionDetailData?.amount}
            </p>
          </span>
          <span className="text-xs text-primary-gray">
            Akan kedaluwarsa pada
          </span>
          <span className="text-primary-black ml-auto text-xs">
            {transactionDetailData?.expiryAt &&
              formatDateInID(transactionDetailData?.expiryAt)}
          </span>{' '}
        </div>
      </div>
      <Button type="button" appearance="primary" fullWidth onClick={onClose}>
        Coba sekarang
      </Button>
    </Modal>
  )
}

export default FreeCoinsModal
