import { useRouter } from 'next/router'
import React from 'react'

import { appActions } from '@features/app/reducers'

import Button from '@components/button'
import Modal from '@components/modal'

import { useAppDispatch, useAppSelector } from '@hooks/redux'

import MODAL_NAME from '@constants/modal-name'

const KycRejectedReverifyModal = () => {
  const appModalName = useAppSelector((state) => state.appReducer.modalName)
  const open = appModalName === MODAL_NAME.KYC_REVERIFY_REJECTED_MODAL
  const dispatch = useAppDispatch()
  const router = useRouter()

  return (
    <Modal
      isCloseButtonHidden
      open={open}
      onClose={() => dispatch(appActions.removeModal())}
    >
      <div className="max-w-[400px]">
        <p className="mb-[24px] text-center">
          Verifikasi ulang datamu agar bisa chat kandidat dan menayangkan loker
          ke kandidat berkualifikasi.
        </p>
        <div className="flex items-center gap-x-[16px]">
          <Button
            type="button"
            appearance="outline-primary"
            fullWidth
            onClick={() => dispatch(appActions.removeModal())}
          >
            Nanti saja
          </Button>
          <Button
            type="button"
            appearance="primary"
            fullWidth
            onClick={() =>
              router.push({ pathname: '/kyc', query: { step: 'pre-kyc' } })
            }
          >
            Verifikasi sekarang
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default KycRejectedReverifyModal
