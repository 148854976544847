import React from 'react'

import { appActions } from '@features/app/reducers'
import CandidateSearchNoticeModal from '@features/candidates/search/components/CandidateSearchNoticeModal'

import { useAppDispatch, useAppSelector } from '@hooks/redux'

import MODAL_NAME from '@constants/modal-name'

const JobListingCandidateSearchNoticeModal = () => {
  const modalName = useAppSelector((state) => state.appReducer.modalName)
  const dispatch = useAppDispatch()

  return (
    <CandidateSearchNoticeModal
      isOpen={modalName === MODAL_NAME.CANDIDATE_SEARCH_NOTICE_MODAL}
      onClose={() => dispatch(appActions.removeModal())}
    />
  )
}

export default JobListingCandidateSearchNoticeModal
