import classNames from 'classnames'
import React, { ReactNode } from 'react'

interface Props extends React.HTMLAttributes<HTMLSpanElement> {
  children: ReactNode
  weight?: 'normal' | 'medium' | 'bold'
}

const H1 = ({ children, weight = 'medium', className }: Props) => (
  <h1
    className={classNames(
      'text-3xl text-primary-black',
      `font-${weight}`,
      className
    )}
  >
    {children}
  </h1>
)

export default H1
