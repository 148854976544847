import React from 'react'

const CoinIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || '100'}
    height={props.height || '100'}
    viewBox="0 0 100 100"
    fill="none"
    {...props}
  >
    <path
      d="M97.4715 34.3634C106.118 60.5541 91.8735 88.8441 65.6328 97.4411C62.0341 98.6406 58.4353 99.3904 54.8366 99.7402C50.2882 100.19 45.7898 99.9901 41.4414 99.2404C37.5427 98.5407 33.7441 97.3911 30.1454 95.8416C29.0457 95.3418 27.9461 94.842 26.8965 94.2922C22.2482 91.843 17.9497 88.6942 14.201 84.8955C13.4513 84.1458 12.7515 83.396 12.1018 82.5963C7.95322 77.748 4.65439 72.05 2.50516 65.6523C2.30523 65.0525 2.1053 64.4528 1.95535 63.853C-5.44203 38.1121 8.75294 10.9217 34.3439 2.5247C40.6416 0.42544 47.0894 -0.324294 53.2872 0.125546C54.4867 0.225511 55.6863 0.325475 56.8859 0.475422C62.084 1.22516 67.0823 2.77461 71.7806 5.02381C72.8302 5.52363 73.8299 6.07344 74.8295 6.62324C78.3783 8.62253 81.6271 11.1216 84.626 13.9207C87.675 16.8696 90.374 20.1684 92.6232 23.9171C94.2726 26.6162 95.6721 29.5151 96.8217 32.614C97.0716 33.1638 97.2716 33.7636 97.4715 34.3634Z"
      fill="#FBCF4F"
    />
    <path
      opacity="0.2"
      d="M92.6229 23.9172L54.7864 99.7403C50.238 100.19 45.7396 99.9902 41.3911 99.2405L84.6258 13.9707C87.6747 16.8697 90.3737 20.2185 92.6229 23.9172Z"
      fill="#FFFFF6"
    />
    <path
      opacity="0.2"
      d="M56.9357 0.475542L14.2008 84.8456C13.4511 84.0959 12.7513 83.3462 12.1016 82.5465L53.337 0.0756836C54.5365 0.175648 55.7361 0.325595 56.9357 0.475542Z"
      fill="#FFFFF6"
    />
    <path
      opacity="0.2"
      d="M74.8292 6.62336L30.095 95.8417C28.9954 95.3419 27.8958 94.8421 26.8462 94.2923L71.7803 5.02393C72.8299 5.52375 73.8295 6.07355 74.8292 6.62336Z"
      fill="#FFFFF6"
    />
    <path
      d="M87.9244 37.4625C88.1243 38.0623 88.2743 38.6121 88.4742 39.2119C94.1722 59.6046 82.8262 81.197 62.4834 87.8946C42.1406 94.5922 20.1984 83.996 12.6511 64.153C12.4511 63.6032 12.2512 63.0034 12.0513 62.4036C5.15373 41.561 16.5997 18.919 37.4923 12.0215C58.4349 5.17391 81.0268 16.5699 87.9244 37.4625Z"
      fill="#F1950D"
    />
    <path
      d="M88.4744 39.2119C94.1724 59.6047 82.8264 81.197 62.4836 87.8946C62.0338 88.0446 61.5839 88.1945 61.1341 88.2945C56.5357 89.644 51.8874 90.0938 47.339 89.794C42.7906 89.4941 38.3922 88.3944 34.2936 86.6451C33.244 86.1952 32.2443 85.6954 31.1947 85.1956C26.6463 82.7465 22.4978 79.4476 19.149 75.3491C18.3493 74.3994 17.5995 73.3498 16.8498 72.3002C15.1504 69.801 13.7509 67.102 12.6513 64.203C6.95331 43.8103 18.2993 22.2179 38.6421 15.5203C41.1912 14.6706 43.7903 14.1208 46.3893 13.7709C47.6889 13.621 49.0384 13.521 50.3379 13.521C55.736 13.421 60.9842 14.4207 65.8824 16.37C66.932 16.7698 67.9817 17.2697 68.9813 17.7695C72.9299 19.7688 76.5786 22.3679 79.6775 25.5667C82.7264 28.6656 85.2755 32.3143 87.2248 36.4629C87.7247 37.3625 88.1245 38.3122 88.4744 39.2119Z"
      fill="#FAAF06"
    />
    <path
      opacity="0.2"
      d="M87.2744 36.4633L61.0837 88.345C56.4853 89.6945 51.837 90.1443 47.2886 89.8444L79.6771 25.6172C82.776 28.7161 85.3751 32.3648 87.2744 36.4633Z"
      fill="#FFFFF6"
    />
    <path
      opacity="0.2"
      d="M50.3877 13.521L19.1488 75.3491C18.3491 74.3994 17.5993 73.3498 16.8496 72.3002L46.3891 13.7709C47.7387 13.621 49.0382 13.521 50.3877 13.521Z"
      fill="#FFFFF6"
    />
    <path
      opacity="0.2"
      d="M69.0316 17.7691L34.2439 86.6447C33.1943 86.1949 32.1946 85.6951 31.145 85.1952L65.8827 16.3696C66.9823 16.7695 68.0319 17.2693 69.0316 17.7691Z"
      fill="#FFFFF6"
    />
    <path
      d="M65.8829 55.406C63.0839 55.1061 60.4848 53.5067 58.9853 50.9076C57.4858 48.3085 57.4359 45.2096 58.5355 42.6605C59.4351 40.2114 59.2852 37.3624 57.8857 34.9632C55.3866 30.6148 49.8385 29.1153 45.4901 31.6144C41.1416 34.1135 39.6422 39.6616 42.1413 44.01C43.5908 46.5591 46.0899 48.1086 48.7889 48.4584C51.488 48.8083 53.9371 50.4078 55.4366 52.9069C56.886 55.456 56.986 58.3549 55.9864 60.8541C54.9867 63.3532 55.0867 66.3021 56.5362 68.8512C59.0353 73.1997 64.5833 74.6991 68.9318 72.2C73.2802 69.7009 74.7797 64.1529 72.2806 59.8044C70.8311 57.4053 68.432 55.8558 65.8829 55.406Z"
      fill="#F1950D"
    />
    <path
      d="M35.6432 73.4499C40.6672 73.4499 44.7399 69.3771 44.7399 64.3531C44.7399 59.3291 40.6672 55.2563 35.6432 55.2563C30.6192 55.2563 26.5464 59.3291 26.5464 64.3531C26.5464 69.3771 30.6192 73.4499 35.6432 73.4499Z"
      fill="#F1950D"
    />
    <path
      d="M65.8829 50.5578C63.0839 50.2579 60.4848 48.6585 58.9853 46.0594C57.4858 43.4604 57.4359 40.3615 58.5355 37.8124C59.4351 35.3632 59.2852 32.5142 57.8857 30.1151C55.3866 25.7666 49.8385 24.2672 45.4901 26.7663C41.1416 29.2654 39.6422 34.8134 42.1413 39.1619C43.5908 41.711 46.0899 43.2604 48.7889 43.6103C51.488 43.9602 53.9371 45.5596 55.4366 48.0587C56.886 50.6078 56.986 53.5068 55.9864 56.0059C54.9867 58.505 55.0867 61.454 56.5362 64.0031C59.0353 68.3515 64.5833 69.851 68.9318 67.3519C73.2802 64.8528 74.7797 59.3047 72.2806 54.9563C70.8311 52.5571 68.432 51.0077 65.8829 50.5578Z"
      fill="#FFEDBF"
    />
    <path
      d="M35.6432 68.6013C40.6672 68.6013 44.7399 64.5285 44.7399 59.5045C44.7399 54.4805 40.6672 50.4077 35.6432 50.4077C30.6192 50.4077 26.5464 54.4805 26.5464 59.5045C26.5464 64.5285 30.6192 68.6013 35.6432 68.6013Z"
      fill="#FFEDBF"
    />
  </svg>
)

export default CoinIcon
